// signInWithGoogle.ts
import { getAuth, linkWithCredential, linkWithPopup, signInWithPopup } from "firebase/auth";
import { auth, provider } from "@/firebase/firebaseApp";
import { GoogleAuthProvider } from "firebase/auth";
// import { useRouter } from "next/navigation";
import { baseUrl } from "./constants";
export const signInWithGoogle = async () => {
  // const router = useRouter();
  try {
    const result = await signInWithPopup(auth, provider);
    console.log("google result", result)
    // This gives you a Google Access Token. You can use it to access the Google API.
    const idToken = await result.user.getIdToken();
    console.log("google id token", idToken)
    localStorage.setItem("USER_TOKEN", idToken);
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.accessToken;

    const request = {
      idToken,
      rememberMe: true,
    };

    const loginResponse = await login(request);

    if (loginResponse.exists) {
      console.log("Existing user");
      // router.push(`/dashboard`);
    } else {
      console.log("New user");
      // router.push(`/register/set-name`);
    }
    // The signed-in user info.
    const user = result.user;
    console.log("User:", user);
    return { user, token };
  } catch (error) {
    const errorCode = (error as any).code;
    const errorMessage = (error as any).message;
    const email = (error as any).customData?.email;
    const credential = GoogleAuthProvider.credentialFromError(error as any);
    console.error("Error during Google sign-in:", { errorCode, errorMessage, email, credential });
    throw error;
  }
};

async function login(request: { idToken: string; rememberMe: boolean }) {
  try {
    const response = await fetch(`${baseUrl}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Login failed");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
}
