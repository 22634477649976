import { useRouter } from "next/navigation";
import { useState } from "react";
import { baseUrl } from "./constants";
import { initFirebase } from "../../../firebase/firebaseApp";
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  signInWithEmailAndPassword,
  updateEmail,
} from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { setCredential } from "@/src/redux/features/seller_slice";
import { resetRegisterState, setEmail as setRegisterEmail } from "@/src/redux/slices/register_slice";
import { RootState } from "../../redux/store";
export const useLogin = () => {
  initFirebase();
  const auth = getAuth();
  const router = useRouter();
  const [error, setError] = useState("");
  const priceId = "price_1OS41xJRNO5oZOWGjk0P4DgE";
  const dispatch = useDispatch();
  const { email, password } = useSelector((state: RootState) => state.register);

  const handleCallbackUrl = async (callback: string) => {
    try {
      console.log("handle callback called", callback);
      if (auth.currentUser) {
        const id = await auth.currentUser.getIdToken();
        const url = new URL(callback);
        url.searchParams.set('token', id);
        return url.toString();
      }
    } catch (error) {
      console.error("Error handling callback URL:", error);
      return null;
    }
  };

  // This function is for handling user data after signin with google
  const setUserData = async (user: any, callback?: string) => {
    try {
      const idToken = await user.getIdToken();

      const url = new URL("/auth/login", baseUrl);

      const response = (await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          idToken,
          rememberMe: true,
        }),
      })) as any;

      if (response.status === 200) {
        const data = await response.json();
        if (data.exists) {
          if (!callback) {
            router.push("/dashboard");
          } else {
            const url = await handleCallbackUrl(atob(callback));
            if (url) {
              window.location.href = url;
            }
            setTimeout(() => {
              router.push("/return-to-app?callback=" + callback);
            }, 200);
          }
        } else {
          console.log("NEW USER");
          router.push(`/register/set-name${callback ? `?callback=${callback}` : ""}`);
        }
      } else {
        setError("An error occurred during login.");
        console.error("Error during login: ", response);
      }
    } catch (error) {
      setError("An unknown error has occurred.");
      console.error("Error during login: ", error);
    }
  };

  // TODO: Proper form validation
  const login = async (callback?: string) => {
    if (email.length === 0) {
      setError("Email is required.");
      return;
    }
    if (password.length === 0) {
      setError("Password is required.");
      return;
    }
    try {
      dispatch(setRegisterEmail(email));
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      const usidToken = await user.getIdToken();
      const url = new URL("/auth/login", baseUrl);
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          idToken: usidToken,
          rememberMe: true,
        }),
      });

      // const response = await fetch(BASE_URL + `/auth/login`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   credentials: "include",
      //   body: JSON.stringify({
      //     idToken: usidToken,
      //     key: siteKey,
      //     rememberMe: true,
      //   }),
      // });

      if (response.status === 200) {
        const loginResponse = await response.json();
        if (loginResponse?.exists) {
          if (!callback) {
            router.push("/dashboard");
          } else {
            try {
              const decodedCallback = decodeURIComponent(atob(callback));
              const callbackUrl = await handleCallbackUrl(decodedCallback);
              if (callbackUrl) {
                window.location.href = callbackUrl;
                const encodedCallbackUrl = btoa(encodeURIComponent(callbackUrl));
                setTimeout(() => {
                  router.push("/return-to-app?callback=" + encodedCallbackUrl);
                }, 200);
              }
            } catch (error) {
              console.error("Error processing callback URL:", error);
              setError("Invalid callback URL");
            }
          }
        } else {
          if (!user?.phoneNumber) {
            router.push(`/linking/phone-number?method=email${callback ? `&callback=${callback}` : ""}`);
          } else {
            router.push(`/register/set-name${callback ? `?callback=${callback}` : ""}`);
          }
        }
      } else {
        setError("An error occurred during login.");
        console.error("Error during login: ", response);
      }
    } catch (error: any) {
      const errorCode = error.code;
      if (errorCode === "auth/invalid-email") {
        setError("Incorrect username or password.");
      } else if (errorCode === "auth/wrong-password") {
        setError("Incorrect username or password.");
      } else {
        setError("An unknown error has occurred.");
        console.error("Error during login: ", error);
      }
    }
  };

  const checkUserExists = async () => {
    try {
      const credential = EmailAuthProvider.credential(email, password);
      dispatch(setCredential(credential));
      dispatch(setRegisterEmail(email));
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setError("Email is already in use");
        return true;
      }
    } catch (error) {
      setError("Error validating email: " + error);
      return true;
    }
    setError("");
    return false;
  };

  const changeEmail = async (newEmail: string) => {
    if (email.length === 0) {
      setError("Email is required.");
      return;
    }
    if (password.length === 0) {
      setError("Password is required.");
      return;
    }
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      if (user) {
        updateEmail(user, newEmail)
          .then(() => {})
          .catch((error) => {
            setError("error updating email: " + error);
          });
      } else {
        setError("error updating email" + "No user signed in");
      }
    } catch (error: any) {
      const errorCode = error.code;
      if (errorCode === "auth/invalid-email") {
        setError("Incorrect username or password.");
      } else if (errorCode === "auth/wrong-password") {
        setError("Incorrect username or password.");
      } else {
        setError("An unknown error has occurred.");
        console.error("Error during login: ", error);
      }
    }
  };

  return {
    error,
    login,
    changeEmail,
    setUserData,
    checkUserExists,
    handleCallbackUrl,
  };
};
